import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import _ from "lodash"
import parse from 'html-react-parser';
import ScrollAnimation from 'react-animate-on-scroll';

import SEO from "../components/seo"
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Error from "../pages/404"
import DetailsHead from '../components/News/NewsDetailsHead'
import DetailsBody from '../components/News/NewsDetailsBody'
import Breadcrumb from "../components/News/NewsDetailsBreadcrumb"
import MoreNews from "../components/News/MoreNews";
import FormBlock from '../components/Home/FormBlock/FormBlock';

import "bootstrap/dist/css/bootstrap.min.css"
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss"
import "../scss/grunticon.scss"
import "../scss/custom.scss"
import "../scss/career-custom.scss"


const NewsTemplate = props => {
    const news = props.data.news
    const global = props.data.glstrapi.globalModule.News_Landing_Page;
    const allnews = props.data.allNews.nodes;
    const [morenews, setMoreNews] = useState(null);
    useEffect(() => {
        var more = _.filter(allnews, (e) => { return e.URL !== news.URL });
        setMoreNews(more);
    },[])
    return (
        <div className="wrapper news-full-details">
            <Header />

            <SEO title={news.Meta_Title ? news.Meta_Title : news.Title} description={news.Meta_Description ? news.Meta_Description : news.Title} image={news.Banner_Image ? news.Banner_Image.url : ''}/>
            <Breadcrumb Pagename={news.Title} />
            <DetailsHead {...news} People={news.Authors && news.Authors.length > 0 ? news.Authors[0] : ''} />
            <DetailsBody {...news} />
            <MoreNews morenews={morenews} />
            <FormBlock {...global} />


            <Footer />
        </div>
    )
}
export default NewsTemplate

export const pageQuery = graphql`
  query newsQuery($id: String!) {
    glstrapi { 
        globalModule {
          News_Landing_Page {
            CTA_1_Label
            CTA_2_Label
            Content
            Name
            Show_Chart
            Select_Form {
              Select
            }
            CTA_1_Link {
              id
            }
            CTA_2_Link {
              id
            }
          }
        } 
      }
        news(id: {eq: $id}) {
          id
          URL
          Meta_Title
          Meta_Description
          Title
          Banner_Image {
              url
              alternativeText
          }
          Date
          Content
          Add_More_Details{
            Add_Content
          }
          Authors {
            Name
            Designation
            Phone
            Image {
              url
              alternativeText
            }
          }
        }
        
        allNews {
            nodes { 
              id
              URL
              Meta_Title
              Meta_Description
              Title
              Banner_Image {
                  url
                  alternativeText
              }
              Tile_Image {
                url
                alternativeText
              }
              Date
              Content
              Add_More_Details{
                Add_Content
              }
              Authors {
                Name
                Designation
                Phone
                Image {
                  url
                  alternativeText
                }
              }
                
            }
          }
  }
`
